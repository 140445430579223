<script>
    import Layout from "@/router/layouts/main";
    import appConfig from "@/app.config";
    import PageHeader from "@/components/page-header";
    
    import Swal from "sweetalert2";
    import axios from "axios";
    /**
     * Dashboard Component
     */
    export default {
        page: {
            title: "Edit Departemen",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        components: {
            Layout,
            PageHeader,
        },
        data() {
            return {
                title: "Edit Departemen",
                items: [
                    {
                        text: "Master",
                        href: "/",
                    },
                    {
                        text: "Departemen",
                        href: "/master/Departemen",
                    },
                    {
                        text: "Edit Departemen",
                        active: true,
                    },
                ],
                // Catch Error Axios
                axiosCatchError: null,
                // variable Page
                master_role: ['one', 'two', 'three'],
                role_selected: '',
                code: '',
                nama: '',
                id: this.$route.params.id,
            };
        },
        mounted() {
            this.getDepartemen();
        },
        methods:{
            getDepartemen(){
                Swal.fire({
                    title: '<i class="fas fa-spinner fa-spin"></i>',
                    text: "Loading...",
                    showConfirmButton: false,
                    allowOutsideClick: false,
                });
                
                let self = this;
                axios.get(process.env.VUE_APP_BACKEND_URL_VERSION + '/api/master/departemen?id=' + self.id).then((response) => {
                    var response_data = response.data;
                    var response_data_fix = response_data.list_data.data[0];
                    //console.log(response_data);
                    if(response_data.code == 200){
                        //console.log(response_data_fix);
                        self.nama = response_data_fix.nama;
                        self.code = response_data_fix.code;
                    }else{
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data.message,
                        });
                    }
                    Swal.close();
                });
            },
            StoreData() {
                let self = this;
                Swal.fire({
                    title: '<i class="fas fa-spinner fa-spin"></i>',
                    text: "Loading...",
                    showConfirmButton: false,
                });
                var FormData = require('form-data');
                var data = new FormData();
                data.append('id', self.id);
                data.append('code', self.code);
                data.append('nama', self.nama);

                var config = {
                    method: 'post',
                    url: process.env.VUE_APP_BACKEND_URL_VERSION + '/api/master/departemen/update',
                    data : data
                };
                axios(config)
                .then(function (response) {
                    console.log(response)
                    let timerInterval;
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Anda akan diarahkan ke halaman master departemen segera",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                        willClose: () => {
                            clearInterval(timerInterval);
                        },
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            self.$router.push({ name: "all-departemen" });
                        }
                    });
                }).catch(e => {
                    this.axiosCatchError = e.response.data.data
                    Swal.close();
                })
            },
        },
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-8">
                <div class="card">
                    <b-form class="p-2" @submit.prevent="StoreData">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <b-form-group class="mb-3" label="Code">
                                        <b-form-input type="text" v-model="code"></b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="col-md-12">
                                    <b-form-group class="mb-3" label="Nama">
                                        <b-form-input type="text" v-model="nama"></b-form-input>
                                    </b-form-group>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <b-button type="submit" variant="primary" class="m-1"><i class="fa fa-save"></i> Simpan</b-button>
                            <b-button type="reset" variant="danger" class="m-1"><i class="fa fa-refresh"></i> Reset</b-button>
                        </div>
                    </b-form>
                </div>
            </div>
        </div>
    </Layout>
</template>
